@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.dashed-line {
    width: 2px; /* Line thickness */
    height: 127px; /* Line length */
    border-left: 2px dashed black; /* Dashed line */
    transform: rotate(120deg); /* Rotate the line by 45 degrees */
  }

  .dashed-line2 {
    width: 2px; /* Line thickness */
    height: 145px; /* Line length */
    border-left: 2px dashed black; /* Dashed line */
    transform: rotate(230deg); /* Rotate the line by 45 degrees */
  }

  .dashed-line3 {
    width: 2px; /* Line thickness */
    height: 127px; /* Line length */
    border-left: 2px dashed black; /* Dashed line */
    transform: rotate(120deg); /* Rotate the line by 45 degrees */
  }